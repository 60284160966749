import { Routes, Route, Link,useNavigate,useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {useLocation} from 'react-router-dom'
import { useState,useEffect,useContext } from "react";
import $ from 'jquery';
//context
import { ThemeContext, themes } from '../Context/Context';
import { UserContext } from '../Context/Context';

import Swal from 'sweetalert2'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Slide} from 'react-awesome-reveal';

import axios from 'axios';
import qs from 'qs';
import { hotjar } from 'react-hotjar';
import CustomButtonBasic from "../Components/CustomButtonBasic";
import { faChevronDown, faSort } from "@fortawesome/free-solid-svg-icons";
import SpinnerLoader from "../Components/SpinnerLoader";
import ReactImageFallback from "react-image-fallback";

import { useSelector, useDispatch } from 'react-redux'
import { setData } from '../Redux/Slice/user'


//service
import logout from "../Services/Function/Auth/logout";


import notify from "../Services/Utility/toast";
import useLocalStorage from "../Services/Hooks/useLocalStorage";
import exchange from "../Services/Function/Auth/exchange";
import sunIcon from "../Assets/Icon/sun_icon.png";
import moonIcon from "../Assets/Icon/moon_icon.png";

const Header = (props) =>{
  // const {getUser,logoutContext,loginContext} = useContext(UserContext)
  const [loading,setLoading] = useState(false)
  const [profile,setProfile] = useState(null)
  const [profileMenu,setProfileMenu] = useState(false)
  const [exploreMenu,setExploreMenu] = useState(false)
  const [bilingualMenu, setBilingualMenu] = useState(false)
  const languageSelector = props.languageSelector;

  const user = useSelector((state) => state.user.value)
  const dispatch = useDispatch()
  // const [isLoggedIn,setIsLoggedIn] = useState((user.token != undefined))
  const isLoggedIn = (user.token != undefined)
  

  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_HJID, process.env.REACT_APP_HOTJAR_HJSV);

    //getProfileSimple
    if(!isLoggedIn){
    }
  }, [])
  
    var location = useLocation();
    const [darkmodeActive, setDarkmodeActive] = useLocalStorage("darkmode", false);
    var name = (location === undefined ? '/' : location.pathname)
    let navigate = useNavigate();

    const notify = (message) => {
        toast(message, {
        position: toast.POSITION.TOP_CENTER,
        className:'bg-red-500 font-semibold text-center text-sm py-0 text-white',
        });
    };

    function logOutOpen(){
        var bg = darkmodeActive ? '#1A1A1A': 'white'
        Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Log out',
            confirmButtonColor:'#B3282C',
            background: bg,
        }).then((result) => {
            if (result.isConfirmed) {
              notify('Please Wait')
              logout()
              .finally(res=>{
                dispatch(setData({}))
                navigate('/')
                notify("Good Bye")
              })
            }
          })

    }

    return(
        <>
            <div id="overlay" className="overlay w-screen h-screen absolute top-0 left-0 bg-gray-900 opacity-[0.2] hidden" style={{zIndex:100}} onClick={hideSidebar}>

            </div>
            <div className="absolute top-0 left-0 flex flex-col justify-center h-full z-2 w-screen" style={{zIndex:101}}>
                <div className="flex justify-between h-auto w-full px-6 lg:px-[106px] 2xl:px-[300px]">
                  <div className="flex menu">
                    <div 
                    className="flex md:hidden flex-col justify-center cursor-pointer pr-3"
                    onClick={showSidebar}
                    >
                        <FontAwesomeIcon className="align-middle" icon="bars" />
                    </div>
                    <div className="flex items-center mr-3">
                      <Link to='/'>
                          <div className="logo">
                          </div>
                      </Link>
                    </div>
                    <div className="items-center mx-3 hidden md:flex relative">
                      <div onClick={()=>{setExploreMenu(!exploreMenu)}} onMouseEnter={()=>{setExploreMenu(true)}} className="cursor-pointer font-bold flex items-center justify-center">
                        Explore <FontAwesomeIcon icon={faChevronDown} className="ml-2"></FontAwesomeIcon>
                        {true ? 
                          <div 
                            onMouseLeave={()=>{setExploreMenu(false)}} 
                            className={`dropdown-card w-[200px] overflow-hidden border-gray-300 mt-[25px] cursor-pointer absolute top-[20px] left-0 bg-white text-md shadow-md rounded-md flex flex-col font-normal ${exploreMenu ? 'show' : ''}`}
                          >
                            <a href='https://alt.cause.id/blogs' target="_blank"><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Blog</div></a>
                            {/* <Link to='/settings#ConnectApps'><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Move For Coffee</div></Link> */}
                            <a href='https://store.cause.id' target="_blank"><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Store</div></a>
                            <Link to='/faq'><div className="py-2 px-6 hover:bg-red-700 hover:text-white">FAQ</div></Link>
                            <Link to='/contactus'><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Contact Us</div></Link>
                          </div>:<></>
                        }
                      </div>
                    </div>
                    <div className="items-center mx-3 hidden md:flex">
                      <Link to='/race' className={`${name=='/race'? 'active' : ''} flex cursor-pointer`}>
                          <div className="font-bold">Events</div>
                      </Link>
                    </div>
                    <div className="items-center mx-3 hidden md:flex">
                      <Link to='/challenges' className={`${name=='/challenges'? 'active' : ''} flex cursor-pointer`}>
                          <div className="font-bold">Challenges</div>
                      </Link>
                    </div>

                  </div>
                  <div className="flex items-center">
                    {
                      !isLoggedIn && props.showLogin ? 
                      <div className="flex">
                        <Link className="border rounded px-4 py-1 font-semibold border-red-800 mr-2 hover:text-white hover:bg-red-800" to={'/login'}>Log In</Link>
                        <Link className="hidden md:block border text-white rounded px-4 py-1 font-semibold border-red-800 mr-2 hover:text-red-800 bg-red-800 hover:bg-white" to={'/signup'}>Sign Up</Link>
                      </div>:<></>
                    }
                    {
                      isLoggedIn ? 
                        (
                          <div className="relative">
                            <div onClick={()=>{setProfileMenu(!profileMenu)}} onMouseEnter={()=>{setProfileMenu(true)}} className="flex items-center font-semibold cursor-pointer relative">
                              <ReactImageFallback
                                src={`${user.user.user_profilepicture == null ? '' : user.user.user_profilepicture.replace("cause.id/img","img.cause.id")}`}           
                                fallbackImage={'https://img.cause.id/dashboard_man.png'}
                                initialImage={`${user.user.user_profilepicture == null ? '' : user.user.user_profilepicture.replace("cause.id/img","img.cause.id")}`}           
                                className="w-[40px] h-[40px] bg-gray-400 mr-2 bg-center bg-cover rounded-full"
                              >
                                
                              </ReactImageFallback>
                              <div className=" mr-2 hidden md:block" >{user.user.user_firstname.length <=14 ? user.user.user_firstname : user.user.user_firstname.substring(0,10)+'...'}</div>
                              <div className=" mr-3"><FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon></div>
                              
                            </div>
                            {profileMenu ? 
                             <div onMouseLeave={()=>{setProfileMenu(false)}} className="w-[200px] overflow-hidden rounded-md mt-[25px] cursor-pointer absolute top-[20px] border border-gray-300 bg-white text-md shadow-md right-0 flex flex-col font-normal">
                              <Link to={'/dashboard'}><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Profile</div></Link>
                              <Link to='/settings#ConnectApps'><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Connect Apps</div></Link>
                              <Link to='/settings#EditProfile'><div className="py-2 px-6 hover:bg-red-700 hover:text-white">Settings</div></Link>
                              <div className="py-2 px-6 hover:bg-red-700 hover:text-white" onClick={logOutOpen}>Log Out</div>
                            </div>:<></>
                            }
                           
                          </div>
                        )
                      :<></>
                    }
                    {
                      languageSelector?.checkBilingual ? 
                      (
                        <div className="relative">
                          <div onClick={()=>{setBilingualMenu(!bilingualMenu)}} onMouseEnter={()=>{setBilingualMenu(true)}} className="w-[58px] flex items-center cursor-pointer relative border border-slate-300 rounded-md px-2.5 py-1.5">
                            <div className="font-medium mr-1">{languageSelector.language}</div>
                            <FontAwesomeIcon icon={faSort} className="h-4 w-4 ml-1" />
                          </div>

                          {bilingualMenu ? 
                           <div onMouseLeave={()=>{setBilingualMenu(false)}} className="w-[58px] overflow-hidden rounded-md mt-[25px] cursor-pointer absolute top-[20px] border border-gray-300 bg-white text-md shadow-md right-0 flex flex-col font-normal">
                              <div onClick={()=> languageSelector.handleLanguage('ID')} className="pl-2 py-1.5 hover:bg-red-700 hover:text-white">ID</div>
                              <div onClick={()=> languageSelector.handleLanguage('EN')} className="pl-2 py-1.5 hover:bg-red-700 hover:text-white">EN</div>
                          </div>:<></>
                          }
                         
                        </div>
                      )
                    :<></>
                    }
                  </div>
                    
                </div>
            </div>

            <div id="sidebar" className="sidebar absolute sidebar-hidden shadow-xl">
                <div className="flex flex-col justify-between h-full w-full pl-[0px]">
                    <div>
                        <div className="flex justify-between md:justify-end pl-[30px] pr-5 pt-5 pb-12">
                          <div className="flex items-center">
                            <div className="">
                            <FontAwesomeIcon 
                            className="cursor-pointer color-primary"
                                icon="times" 
                                size="lg"
                                onClick={hideSidebar}/>
                            </div>
                          </div>
                            
                        </div>
                        <div className="menu">
                            <Link onClick={hideSidebar} to='/' className={`${name=='/'? 'active' : ''} flex md:hidden cursor-pointer my-4`}>
                                <div className={`navbar-icon icon-home ml-3 mr-0`}></div>
                                <div className="font-bold">Home</div>
                            </Link>
                            <Link onClick={hideSidebar} to='/race' className={`${name=='/race'? 'active' : ''} flex md:hidden cursor-pointer my-4`}>
                                <div className="navbar-icon icon-event ml-3 mr-0"></div>
                                <div className="font-bold">Events</div>
                            </Link>
                            <Link onClick={hideSidebar} to='/challenges' className={`${name=='/challenges'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-challenge ml-3 mr-0"></div>
                                <div className="font-bold">Challenges</div>
                            </Link>
                            <a href='https://alt.cause.id/blogs' onClick={()=>{hotjar.event('blog-sidebar-click');}} className={`flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-blog ml-3 mr-0"></div>
                                <div className="font-bold">Blog</div>
                            </a>
                            {/* <Link onClick={hideSidebar} to='/move4coffee' className={`${name=='/move4coffee'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-coffee ml-3 mr-0"></div>
                                <div className="font-bold">Move For Coffee</div>
                            </Link> */}
                            
                            <a href='https://store.cause.id' onClick={()=>{hotjar.event('store-sidebar-click');}} className={`flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-store ml-3 mr-0"></div>
                                <div className="font-bold">Store</div>
                            </a>
                            {/* <Link to='/blogs' className={`${name=='/blogs'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-blog ml-3 mr-0"></div>
                                <div className="font-bold">Blog</div>
                            </Link> */}
                            
                            
                            <Link to='/contactus' className={`${name=='/contactus'? 'active' : ''} flex md:hidden cursor-pointer my-4`}>
                                <div className="navbar-icon icon-contact ml-3 mr-0"></div>
                                <div className="font-bold">Contact Us</div>
                            </Link>
                            {isLoggedIn ? (
                            <>
                                {/* <Link to='/settings#ConnectApps' className={`${name=='/settings#ConnectApps'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-sync ml-3 mr-0"></div>
                                <div className="font-bold">Connect Apps</div>
                                </Link> */}
                                
                                {/* <div onClick={logOut} className={`${name=='/settings#LogOut'? 'active' : ''} flex cursor-pointer my-4`}>
                                    <div className="navbar-icon icon-logout ml-3 mr-0"></div>
                                    <div className="font-bold">Log Out</div>
                                </div> */}
                            </>

                            ) : (
                                <></>
                            )}
                            <Link to='/faq' className={`${name=='/faq'? 'active' : ''} flex cursor-pointer my-4`}>
                                <div className="navbar-icon icon-faq ml-3 mr-0"></div>
                                <div className="font-bold">Faq</div>
                            </Link>
                            <div className="flex cursor-pointer my-4">
                              <div className="navbar-icon ml-3 mr-0 pl-4">
                                  <ThemeContext.Consumer>
                                  {({ changeTheme }) => (
                                  <div onClick={()=>{
                                    setDarkmodeActive(!darkmodeActive);
                                    changeTheme(darkmodeActive ? themes.light : themes.dark)
                                  }}  className={`darkmode-toggle-container ${darkmodeActive ? 'dark' : ''}`}>
                                    <div className={`bullet`}>
                                      <div class="sun" id="sun">
                                        <img src={sunIcon} alt="sun" />
                                      </div>
                                      <div class="moon" id="moon">
                                        <img src={moonIcon} alt="moon" />
                                      </div>
                                    </div>
                                  </div>
                                  )}
                                  </ThemeContext.Consumer>
                              </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='flex justify-center pb-28'>
                    
                    </div>
                    
                </div>
            </div>
        </>
    )
}
$('#overlay').hide()
function showSidebar(){
    $('#sidebar').removeClass('sidebar-hidden');
    $('#overlay').show()
};
function hideSidebar(){
    $('#sidebar').addClass('sidebar-hidden');
    $('#overlay').hide();
}

export default Header